<template>
     <CForm ref="form" class="needs-validation">
        <CInput
            placeholder="Email Adres"
            type="email"
            autocomplete="email"
            v-model="data.emailAddress"
            :readonly="true"
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>
        <CInput
            placeholder="Ad"
            type="text"
            autocomplete="text"
            v-model="data.firstName"
            :readonly="true"
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>
        <CInput
            placeholder="Soyad"
            type="text"
            autocomplete="text"
            v-model="data.lastName"
            :readonly="true"
        >
            <template #prepend-content>
            <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>
        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
            <b>Hesap Aktif</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
            <CSwitch
                class="mr-1"
                size="lg"
                color="primary"
                :checked.sync="data.activeFlag"
            />
            </CCol>
            <CCol tag="label" sm="2" class="col-form-label">
            <b> Email Aktif</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
            <CSwitch
                class="mr-1"
                size="lg"
                color="primary"
                :checked.sync="data.emailValidFlag"
            />
            </CCol>
        </CRow>
       
    </CForm>
</template>

<script>
    export default{
        name: "CustomerInformation",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            validated: Boolean,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>